import React from "react"
import styled from 'styled-components'

const Quote = styled.div`
    background-color: #141414;
    padding: 30px;
    border-left: 3px solid;
    font-size: initial;
`;

export default Quote;
