import React from "react"
import { Link } from "gatsby"
import Header from "../components/header"
import Footer from "../components/footer"
import Navbar from "../components/navbar"
import Button from "../components/button"
import Quote from "../components/quote"
import { FaArrowLeft } from 'react-icons/fa';

let quotes = [
  "An investment in knowledge pays the best interest.",
  "Failure is simply the opportunity to begin again, this time more intelligently.",
  "If you hear a voice within you say 'you cannot paint,' then by all means paint and that voice will be silenced.",
  "If you're going through hell, keep going.",
  "Always focus on how far you've come, not how far you have to go.",
  "Continuous improvement is better than delayed perfection.",
  "You may delay but time shall not.",
  "The best way to predict the future is to create it.",
  "Pressure makes diamonds."
]

function randomQuote(quotes) {
  return quotes[Math.floor(Math.random()*quotes.length)]
}

export default () => (
  <div style={{ color: `teal` }}>
    <Navbar>
      <Link to="/"><FaArrowLeft /></Link>
    </Navbar>
    <Header headerText="404" />
    <p>Whoops, I don't think this is the page you're looking for.</p>
    <p>Here's some wisdom from a random quote.</p>
    <Quote>{randomQuote(quotes)}</Quote>
    <Link to="/"><Button>Home</Button></Link>
    <Footer />
  </div>
)